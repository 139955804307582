<template>
	<modal
		class="modal-profie announcement.con-vs-dialog"
		size="small-medium"
		:hide-footer="true"
		:show-close="true"
		:isEnableKeyUp="false"
		@close="close"
		v-if="show">
		<div slot="header">
			<h5 class="modal-title" v-if="Object.entries(this.announcement).length === 0">{{  $t('Add') }} <span>{{ $t('Announcement') }}</span></h5>
			<h5 class="modal-title" v-if="Object.entries(this.announcement).length > 0 && !isEdit && announcement.user && announcement.user && announcement.user.name">{{ $t('Profile') }} <span>{{ announcement.user.name }}</span></h5>
			<h5 class="modal-title" v-if="Object.entries(this.announcement).length > 0 && isEdit">{{  $t('Edit') }} <span>{{ $t('Announcement') }}</span></h5>
		</div>
		<div slot="body">
			<div>
				<announcement-edit-form @close="close" @add="add" :announcement="announcement" :eventId="eventId" v-if="isEdit || Object.entries(this.announcement).length === 0" />
				<announcement-details @showDeleteAnnouncement="showDeleteAnnouncement" @editToggle="editToggle" :announcement="announcement" v-if="!isEdit && Object.entries(this.announcement).length > 0" />
			</div>
		</div>
	</modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/Modal.vue';
import AnnouncementEditForm from '@/components/announcements/form/AnnouncementEditForm.vue';
import AnnouncementDetails from '@/components/announcements/form/AnnouncementDetails.vue';

export default {
  components: {
    Modal,
    AnnouncementEditForm,
    AnnouncementDetails,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: Number,
      default: null,
    },
    announcement: {
      type: Object,
      default: null,
    },
    isEditFromParent: {
      type: Boolean,
      default: false,
    },
    isDetailsFromParent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accessCode: null,
      isSubmitting: false,
      isEdit: false,
      isSaving: false,
      dataAnnouncement: {
        name: '',
        company: '',
        email: '',
        language: '',
      },
    };
  },
  watch: {
    show() {
      if (this.show) {
        if (this.isEditFromParent) {
          this.isEdit = true;
        }
        if (this.isDetailsFromParent) {
          this.isEdit = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
  },
  methods: {
    close() {
      this.$emit('close');
      this.isEdit = false;
    },
    add(item) {
      this.$emit('add', item);
    },
    editToggle() {
      this.isEdit = true;
    },
    showDeleteAnnouncement(item) {
      this.$emit('showDeleteAnnouncement', item);
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>

<style lang="scss">
  .announcement.con-vs-dialog .vs-dialog {
    min-width: 600px!important;
  }
</style>
