<template>
  <div>
    <div class="space-y-4">
      <div>
        <vs-input v-filter-input v-validate="'required'" data-vv-validate-on="blur" name="title" type="text"
          :label="$t('Title')" :placeholder="$t('Title')" class="w-full" v-model="dataName" />
        <span class="text-danger text-sm">{{ $t(errors.first('title')) }}</span>
      </div>
      <!-- <div class="flex"> -->
        <!-- <div class="mt-5 w-1/2 pb-6 mr-2">
          <label class="vs-select--label w-full">
            {{ $t('Start Date') }}
          </label>
          <div class="input-group relative">
            <flat-pickr class="date-event" :placeholder="$t('Start Event')"
              :config="configStartDateTimePicker" v-model="dataStartDate" />
            <div class="icon-calendar-event">
              <feather-icon icon="CalendarIcon" />
            </div>
          </div>
        </div> -->

        <!-- End Time -->
        <!-- <div class="mt-5 w-1/2 pb-10 ml-2">
          <label class="vs-select--label">
            {{ $t('End Date') }}
          </label>
          <div class="input-group relative">
            <flat-pickr class="date-event" :placeholder="$t('End Event ')"
              :config="configEndDateTimePicker" v-model="dataEndDate" />
            <div class="icon-calendar-event">
              <feather-icon icon="CalendarIcon" />
            </div>
          </div>
        </div> -->
      <!-- </div> -->
      <div>
        <vs-input v-filter-input v-validate="'required'" data-vv-validate-on="blur" name="description" type="text"
          :label="$t('Message')" :placeholder="$t('Description')" class="w-full" v-model="dataDescription" />
        <span class="text-danger text-sm">{{ $t(errors.first('description')) }}</span>
      </div>
      <div class="text-right">
        <vs-button :class="{'is-loading': isSaving}" :disabled="!isFormValid" @click="submitData()"
          color="primary" type="filled" class="submit-button">
          {{ $t('Save') }}
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  duplicateVar,
  getFirstCharacter,
  generateColorByAlphabet,
  getAxiosErrorMessage,
} from '@/lib/helper';
import announcementsApi from '@/api/announcement';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

dayjs.extend(utc);
export default {
  components: {
    // flatPickr,
  },
  props: {
    announcement: {
      type: Object,
      default: () => {},
    },
    eventId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      languageOptions: [
        { value: 'no', text: 'Norsk' },
        { value: 'en', text: 'English' },
      ],
      dataId: null,

      dataName: '',
      dataCompany: '',
      dataEmail: '',
      dataDescription: '',
      dataStartDate: '',
      dataEndDate: '',

      dataImg: null,
      isSaving: false,
      isUploading: false,
      configStartDateTimePicker: {
        enableTime: true,
        altInput: true,
        altFormat: 'd F Y - h:i K',
        dateFormat: 'Y-m-d H:i',
        time_24hr: false,
      },
      configEndDateTimePicker: {
        enableTime: true,
        altInput: true,
        altFormat: 'd F Y - h:i K',
        dateFormat: 'Y-m-d H:i',
        time_24hr: false,
      },
    };
  },
  computed: {
    params() {
      const params = {
        id: this.dataId,
        title: this.dataName,
        start_date: this.dataStartDate,
        end_date: this.dataEndDate,
        description: this.dataDescription,
        type: 'user',
        is_active: false,
      };
      return params;
    },
    isFormValid() {
      return (
        !this.errors.any()
				&& this.dataName !== ''
				&& this.dataDescription !== ''
      );
    },
  },
  methods: {
    setData() {
      const announcement = duplicateVar(this.announcement);
      const startTime = dayjs.utc(announcement.start_date).format('YYYY-MM-DD HH:mm');
      const endTime = dayjs.utc(announcement.end_date).format('YYYY-MM-DD HH:mm');
      this.dataStartDate = startTime;
      this.dataEndDate = endTime;
      this.dataId = announcement.id;
      this.dataName = announcement.title;
      this.dataDescription = announcement.description;
      this.initValues();
    },
    initValues() {
      if (this.announcement.id) return;
      this.dataId = null;
      this.dataName = '';
      this.dataEndDate = '';
      this.dataStartDate = '';
      this.dataDescription = '';
    },
    submitData() {
      const submit = (result) => {
        this.isSaving = true;
        if (!result) return;
        const title = this.$t('Announcement');
        const isUpdate = !!this.dataId;
        const params = this.params;
        const callback = (response) => {
          const item = response.data;
          this.$store.dispatch('setAnnouncement', item);
          const message = response.message;
          this.$emit('add', item);
          this.$emit('close');
          this.initValues();
          this.$vs.notify({
            title,
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
          });
          this.isSaving = false;
        };
        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title,
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isSaving = false;
        };
        if (isUpdate) {
          announcementsApi.update(this.dataId, params, callback, errorCallback);
        } else {
          announcementsApi.create(params, callback, errorCallback);
        }
      };
      this.$validator.validateAll().then(submit);
    },
    removeImage() {
      this.dataImg = null;
      this.$refs.updateImgInput.value = '';
    },
    getInitial(str) {
      if (str) {
        return getFirstCharacter(str);
      }
      return 'A';
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
  },
  mounted() {
    this.initValues();
  },
  created() {
    this.configStartDateTimePicker.minDate = dayjs().format('YYYY-MM-DDTHH:mm:ss');
    this.configEndDateTimePicker.minDate = dayjs().format('YYYY-MM-DDTHH:mm:ss');
    this.setData();
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
.date-event{
	width: 100%;
	padding: 10px;
	padding: .7rem;
	font-size: 1rem;
	border-radius: 5px;
	border: 1px solid rgba(0, 0, 0, .2);

	&.active {
		border: 1px solid #0154C7;
	}
}
.date-picker{
	width: 100%;
}
.sub-title-public{
	color: grey;
	font-size: 13px;
}
.icon-calendar-event{
	color: grey;
	position: absolute;
	top: 0;
	margin: 0.3em;
	right: 0;
}
</style>
