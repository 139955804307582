<template>
	<div>
		<div v-if="dataImg == null" class="text-center pointer" @click="$refs.updateImgInput.click()">
		<vs-avatar v-if="!announcement" size="70px">
			<img src="@/assets/images/file-icons/default-img.jpg" alt="asd" class="responsive pointer" />
		</vs-avatar>
		<vs-avatar class="text-4xl" :color="getColor(announcement && announcement.name)" :text="getInitial(dataName)" v-if="announcement" size="70px">
		</vs-avatar>
		</div>
		<div v-if="dataImg != null" class="text-center" @click="$refs.updateImgInput.click()">
			<img :src="dataImg" alt="img" style="width: 70px; height: 70px" class="responsive rounded-full" />
		</div>
		<div class="space-y-6">
			<div>
				<div class="text-gray border-bottom-2">{{ $t('Title') }}</div>
				<div>{{dataName}}</div>
			</div>
			<div>
				<div class="text-gray">{{ $t('Start Time') }}</div>
				<div>{{dataStartDate}}</div>
			</div>
			<div>
				<div class="text-gray">{{ $t('End Time') }}</div>
				<div>{{dataEndDate}}</div>
			</div>
			<div>
				<div class="text-gray">{{ $t('Description') }}</div>
				<div>{{dataDescription}}</div>
			</div>
		</div>
		<div class="mt-8 flex justify-between items-center">
			<div class="text-blue pointer" @click="showDeleteAnnouncement()">
				Delete this profile
			</div>
			<vs-button
				@click="editToggle()"
				color="primary"
				type="filled"
				class="submit-button"
				>
				{{ $t('Edit') }}
			</vs-button>
		</div>
	</div>
</template>

<script>
/* eslint-disable camelcase */
import {
  duplicateVar,
  getFirstCharacter,
  generateColorByAlphabet,
} from '@/lib/helper';

export default {
  components: {
  },
  props: {
    announcement: {
      type: Object,
      default: () => {},
    },
    eventId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      languageOptions: [
        { value: 'no', text: 'Norsk' },
        { value: 'en', text: 'English' },
      ],
      dataId: null,
      dataEventId: null,

      dataName: '',
      dataStartDate: '',
      dataEndDate: '',
      dataDescription: '',

      dataImg: null,
      isSaving: false,
      isUploading: false,
    };
  },
  computed: {
  },
  methods: {
    showDeleteAnnouncement() {
      this.$emit('showDeleteAnnouncement', this.announcement);
    },
    setData() {
      const announcement = duplicateVar(this.announcement);

      this.dataId = announcement.id;
      this.dataName = announcement.title;
      this.dataStartDate = announcement.start_date;
      this.dataEndDate = announcement.end_date;
      this.dataDescription = announcement.description;
    },

    editToggle() {
      this.$emit('editToggle');
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
  },
  mounted() {
  },
  created() {
    this.setData();
  },
  destroyed() {
  },
};
</script>
